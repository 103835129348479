import { FC, useEffect, useState } from "react";
import Button from "../../shared/Button";
import useElementSize from "../../hooks/useElementSize";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import useScrollPosition from "../../hooks/useScrollPosition";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";

// @ts-ignore
import mainimg from "../../assets/mainimg.png";
// @ts-ignore
import exiticon from "../../assets/exiticon.svg";
// @ts-ignore
import closeiconwhite from "../../assets/closeiconwhite.svg";

const Hero: FC = () => {
  const [isBannerFixed, setIsBannerFixed] = useState<boolean>(false);
  const [bannerInitialPosition, setBannerInitialPosition] = useState<number>(0);
  const [isShownBanner, setIsShownBanner] = useState<boolean>(true);

  const [container, { width }] = useElementSize();
  const [bannerRef, { offsetTop: bannerOffsetTop }] = useElementSize();

  const scrollDirection = useScrollDirection();
  const scrollY = useScrollPosition();

  useEffect(() => {
    if (bannerOffsetTop > 0) {
      setBannerInitialPosition(bannerOffsetTop);
    }
  }, [bannerOffsetTop]);

  useIsomorphicLayoutEffect(() => {
    if (scrollDirection === "up") {
      setIsBannerFixed(false);
    }

    if (scrollDirection === "down" && scrollY > bannerInitialPosition) {
      setIsBannerFixed(true);
    }
  }, [scrollY]);

  return (
    <section id="home">
      <div
        ref={container}
        className="bg-seconary-100 xs:px-[24px] xs:py-[20px] xs:pt-[84px] sm:px-[96px] sm:py-[30px] sm:pt-[150px] md:px-[139px] md:py-[50px] md:pt-[170px] lg:flex lg:justify-between lg:px-[153px] lg:py-[100px] lg:pr-[35px] lg:pt-[164px]"
      >
        <div className="xs:mb-9 xs:p-0 sm:mb-12 sm:pt-4 md:mb-0 md:basis-full lg:basis-2/5">
          <div className="relative">
            <a 
            href="https://www.waiverking.com/"
            target="_blank"
            className="relative z-10 w-full lg:w-[31rem] items-center gap-1 [@media(min-width:769px)]:gap-14 rounded-[10px] border-[1px] border-solid border-black bg-primary-010 xs:mb-[60px] xs:px-[11px] xs:py-4 sm:mb-20 flex flex-col [@media(min-width:769px)]:flex-row sm:justify-between sm:p-5 md:mb-14 md:px-[22px] md:py-[11px]">
              <p className="xs:text-md font-poppins font-bold text-primary-200 xs:text-center sm:text-base">
                Not a WKForm user?
              </p>
              <div className="flex gap-2 justify-center">
                <span className="text-primary-700">
                  Open WaiverKing MBO site
                </span>
                <img
                  className="w-4"
                  src={exiticon}
                  alt="Link to WaiverKing MBO site"
                />
              </div>
            </a>
            <span className="absolute block h-full w-full lg:w-[31rem] rounded-[10px] border-[1px] border-solid border-black shadow-[rgba(0,0,0,0.24)_0px_3px_8px] xs:bottom-[-5px] xs:left-[5px] sm:bottom-[-10px] sm:left-[10px]"></span>
          </div>
          <div className="mb-8 font-poppins font-bold text-primary-700 w-full lg:w-[612px]">
            <h1 className="xs:mb-6 xs:text-3xl xs:leading-normal sm:mb-5 sm:text-4xl sm:leading-normal">
              Transform Your PDFs into Dynamic, Mobile-Friendly Forms with
              WKForm!
            </h1>
            <p className="font-roboto font-normal text-primary-700 xs:text-sm sm:text-base">
              Say goodbye to the hassle of static PDFs and hello to seamless,
              dynamic forms that your customers can fill out anywhere—on their
              phone, tablet, or PC. With WKForm, turning your old paper forms
              into easy-to-use online forms has never been easier!
            </p>
          </div>
          <div className="w-fit">
            <Button
              text={"Sign up for a free 15 day trial"}
              style={"bg-primary-400 text-primary-010 mb-1"}
              href={"/signup"}
            />
            <p className="text-center text-sm">No credit card required</p>
          </div>
        </div>
        <div className="flex items-end justify-center lg:mb-[-100px]">
          <img
            className="xs:w-[330px] sm:w-[418px] md:w-[612px] lg:w-full"
            src={mainimg}
            alt="mainimg"
          />
        </div>
      </div>
      <div
        ref={bannerRef}
        style={{
          width,
          transform: `translateY(${isBannerFixed ? "0" : "-100"}px)`,
        }}
        className={`fixed top-0 z-[1000] flex justify-center border border-b-primary-010 bg-primary-200 opacity-90 transition-transform duration-1000 ease-in-out 
        ${!isShownBanner && "hidden"}`}
      >
        <div className="flex items-center xs:px-6 xs:py-[11px] sm:gap-7 sm:px-0 sm:py-2">
          <p className="font-poppins font-medium text-primary-010 xs:text-sm sm:text-lg">
            Get Started Today with a 15-Day Free Trial!
          </p>
          <Button
            text={"Sign up"}
            style={
              "text-primary-700 font-normal text-base bg-primary-010 h-[34px] sm:mr-0 sm:ml-0 xs:mr-8 xs:ml-12 xs:min-w-[95px]"
            }
            href={"/signup"}
          />
        </div>
        <button
          className="absolute mx-[38px] cursor-pointer xs:right-[-33px] xs:top-[5px] sm:right-[-8] sm:top-[50%] sm:translate-y-[-50%] lg:right-0"
          onClick={() => setIsShownBanner(!isShownBanner)}
        >
          <img src={closeiconwhite} alt="close" />
        </button>
      </div>
    </section>
  );
};
export default Hero;

const BASE_YEAR = 2000;
const MONTH_OFFSET = 1;

export const isCardExpired = (date: string) => {
  const [month, year] = date.split("/").map(Number);
  const expirationDate = new Date(BASE_YEAR + year, month - MONTH_OFFSET);
  const currentDate = new Date();

  return currentDate >= expirationDate;
};

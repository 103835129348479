import { FC, RefObject } from "react";
import { QuestionType } from "../../shared/types";
import Heading from "../../shared/Heading";
import Question from "./Question";

interface FAQProps {
  faqRef: RefObject<HTMLDivElement>;
}
const FAQ: FC<FAQProps> = ({ faqRef }) => {
  const questions: Array<QuestionType> = [
    {
      question: "What operating systems does WKForm support?",
      answer: "All.",
    },
    {
      question: "What hardware do you require?",
      answer:
        "The WKForm app has been developed to provide convenient access to WKForm at your front desk sign-in station. A device that runs either iOS for iPad, or Android will ensure that customers who choose not to complete their documents at home can quickly and easily complete the required forms upon arrival without backing up at your front desk.",
    },
    {
      question: "How much will it cost my studio per month?",
      answer:
        "The pricing of WKForm is based on the number of transactions completed each month. See the pricing details section for complete details.",
    },
    {
      question:
        "Do customers receive a copy of their eWaivers / eDocuments eForms?",
      answer: `<li>Yes! They can do it.</li>
         <li>We can also switch off the email copy for PPI / HIPAA compliancy.</li>
         <li>The end user (your client) can also choose to 'not send' email copy to their inbox.</li>`,
    },
  ];

  return (
    <section id="faq" ref={faqRef}>
      <div className="xs:px-[24px] xs:pb-[70px] xs:pt-[20px] sm:px-[96px] sm:pb-[100px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading>Frequently asked questions</Heading>
        {questions.map((question) => (
          <Question {...question} key={question.question} />
        ))}
      </div>
    </section>
  );
};
export default FAQ;

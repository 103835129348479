import { FC } from "react";

import Heading from "../../shared/Heading";
import { FeatureType } from "../../shared/types";
import registerStepsData from "../../data/registerStepsData";
import Step from "./Step";

const Register: FC = () => {
  return (
    <section id="register">
      <div className="xs:px-[24px] xs:pb-[70px] xs:pt-[10px] sm:px-[80px] sm:pb-[90px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <div className="mb-20">
          <Heading>
            Register with these <span className="text-primary-200">Quick & Easy</span> Steps
          </Heading>
          <div className="flex flex-wrap gap-7 sm:gap-y-20">
            {registerStepsData.map((step: FeatureType) => (
              <Step
                key={step.title}
                icon={step.icon}
                title={step.title}
                description={step.description}
              />
            ))}
          </div>
        </div>
        <div className="text-primary-700">
          <p>Any Questions?</p>
          <p>Phone: 1-888-211-6693</p>
          <p>
            Click here to{" "}
            <a
              href="mailto:contact_us@waiverking.com"
              className="text-primary-200 underline"
            >
              Email Us!
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
export default Register;

import { FC, useState, SyntheticEvent, ReactNode, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Link,
  Modal,
  Radio,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { InputWidget } from "../../widgets/FormWidgets/InputWidget";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../../store";
import {
  getCompanyDetailsThunk,
  getPersonalDetailsThunk,
  sendCompanyDetailsThunk,
  sendPersonalDetailsThunk,
} from "../../../store/features/thunk/thunkApi";
import { Delete } from "@mui/icons-material";
//@ts-ignore
import visa from "../../../assets/img/visa.svg";
//@ts-ignore
import master from "../../../assets/img/master.svg";

import { changeDefaultPaymentMethodThunk } from "../../../store/features/thunk/paymentThunk/paymentThunk";
// @ts-ignore
import Illustration from "../../../assets/img/Illustration.svg";
import { BusinessAgreement } from "../../BusinessArgeement/BusinessArgeement";
import { CompanyDetails } from "../../../store/features/company/companySlice";
import { SelectWidget } from "components/widgets/FormWidgets/SelectWidget";
import { Loader } from "../../Loader/Loader";
import { LoadingButton } from "@mui/lab";
import { PhoneWidget } from "../../widgets/FormWidgets/PhoneWidget";

// @ts-ignore
import { ReactComponent as PasswordIcon } from "../../../assets/img/password.svg";
// @ts-ignore
import { ReactComponent as EditIcon } from "../../../assets/img/edit.svg";
// @ts-ignore
import { ReactComponent as GoogleIcon } from "../../../assets/img/google.svg";
// @ts-ignore
import { ReactComponent as EmailIcon } from "../../../assets/img/email.svg";
// @ts-ignore
import { ReactComponent as IphoneIcon } from "../../../assets/img/iphone.svg";

import { ChangePasswordModal } from "../../Modal/ChangePasswordModal";
import { TwoFactorAuthenticationModal } from "../../Modal/TwoFactorAuthenticationModal";
import { OtpMethod } from "../../../store/features/form/formSlice";
import { getAuthInfo } from "../../../store/features/api/clientApi/clientApi";
import { showErrorNotification } from "../../../store/features/notification/notificationSlice";
import { DisableVerificationModal } from "../../Modal/DisableVerificationModal";
import { EmergencyActions } from "./EmergencyActions/EmergencyActions";
import Billing from "./BillingComponent";
import { CustomSwitch } from "../../CustomSwitch/CustomSwitch";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AccountComponent: FC = () => {
  const [value, setIValues] = useState(0);
  const [allowedTabs, setAllowedTabs] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAuthInfo, setIsLoadingAuthInfo] = useState(false);
  const [authenticationInfo, setAuthenticationInfo] = useState<any[]>([]);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] =
    useState(false);
  const [isDisableVerificationModalOpen, setIsDisableVerificationModalOpen] =
    useState(false);
  const [authenticationType, setAuthenticationType] = useState<
    OtpMethod["type"] | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  const formMethods = useForm();

  const { handleSubmit, setValue, watch } = formMethods;

  const country = watch("country");

  const { companyDetails, userProfile, isNewUser } = useAppSelector(
    ({ company }) => company,
  );

  const dispatchThunk = useAppThunkDispatch();

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    switch (value) {
      case 0:
        await dispatchThunk(sendCompanyDetailsThunk(data as CompanyDetails));
        setAllowedTabs((prevState) => [...prevState, 1]);
        (isNewUser || companyDetails?.unsignedDocuments) && setIValues(1);
        break;
      case 1:
        await dispatchThunk(sendPersonalDetailsThunk(data));
        setAllowedTabs((prevState) => [...prevState, 2]);
        (isNewUser || companyDetails?.unsignedDocuments) && setIValues(2);
        break;
      // case 2:
      // navigate('/locations/newLocation');
      // await dispatchThunk(resetPasswordConfirmThunk(data));
      // setAllowedTabs((prevState) => [...prevState, 3]);
      // setIValues(3);
      // break;
      // case 3:
      //   await dispatchThunk(resetPasswordConfirmThunk(data));
      //   setAllowedTabs((prevState) => [...prevState, 3]);
      //   setIValues(3);
      //   break;
    }
    setIsLoading(false);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIValues(newValue);
  };

  const getTwoFAInfo = () => {
    setIsLoadingAuthInfo(true);

    dispatch(getAuthInfo.initiate({}))
      .unwrap()
      .then((res) => {
        setAuthenticationInfo(res?.methods);
        setIsLoadingAuthInfo(false);
      })
      .catch((error) => {
        dispatch(
          showErrorNotification({
            message: error?.data?.message ?? error?.data?.error,
          }),
        );
        console.error(error);
      });
  };

  const isAuthenticationOn = (type: string): boolean => {
    return authenticationInfo.some((obj) => obj.type === type);
  };

  const getAuthenticationIndex = (type: string): number => {
    return authenticationInfo.findIndex((obj) => obj.type === type);
  };

  useEffect(() => {
    if (value === 0) {
      if (companyDetails) {
        const entries = Object.entries(companyDetails);
        entries.forEach(([name, value]) => {
          setValue(name, value);
        });
      } else {
        setTimeout(() => {
          dispatchThunk(getCompanyDetailsThunk());
        }, 1000);
      }
    }
  }, [companyDetails, value]);

  useEffect(() => {
    if (value === 1) {
      if (userProfile) {
        const entries = Object.entries(userProfile);
        entries.forEach(([name, value]) => {
          setValue(name, value);
        });
      } else {
        dispatchThunk(getPersonalDetailsThunk());
      }
    }
  }, [userProfile, value]);

  useEffect(() => {
    if (value === 3) {
      getTwoFAInfo();
      dispatchThunk(getPersonalDetailsThunk());
    }
  }, [value]);

  return (
    <>
      <Loader isLoading={!companyDetails || isLoadingAuthInfo} />
      <>
        <Box component={"div"} sx={{ padding: "0 50px 0 305px" }}>
          <Box
            component={"div"}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor={"secondary"}
              textColor={"secondary"}
              aria-label="basic tabs example"
            >
              <Tab label="Company details" {...a11yProps(0)} />
              <Tab
                disabled={
                  !!companyDetails?.unsignedDocuments &&
                  !allowedTabs.includes(1)
                }
                label="Personal details"
                {...a11yProps(1)}
              />
              <Tab
                disabled={
                  !!companyDetails?.unsignedDocuments &&
                  !allowedTabs.includes(2)
                }
                label="Business agreement"
                {...a11yProps(2)}
              />
              {!companyDetails?.unsignedDocuments && (
                <Tab label="Security" {...a11yProps(3)} />
              )}
              {!companyDetails?.unsignedDocuments && (
                <Tab label="Billing" {...a11yProps(4)} />
              )}
              {!companyDetails?.unsignedDocuments && (
                <Tab label="Emergency actions" {...a11yProps(5)} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box
                  component={"div"}
                  sx={{ textAlign: "start", paddingTop: "40px" }}
                >
                  <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
                    About your Company
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    Add information about your Company
                  </Typography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "30px",
                    maxWidth: "400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"name"}
                      label={"Company name"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "30px",
                    maxWidth: "400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"website"}
                      label={"Company website"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "30px",
                    maxWidth: "400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"address"}
                      label={"Address"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "30px",
                    maxWidth: "700px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <SelectWidget
                      fieldId={"country"}
                      label={"Country"}
                      required={true}
                      type={"select"}
                      disabled={true}
                      value={country}
                    />
                  </div>
                  <div key={"region"} style={{ width: "100%" }}>
                    <SelectWidget
                      fieldId={"region"}
                      label={"State / Province"}
                      type={"select"}
                      country={country}
                      required={true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "30px",
                    maxWidth: "700px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"postalCode"}
                      label={"ZIP / PostalCode"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                  <div key={"area3"} style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"city"}
                      label={"City"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                    gap: "26px",
                    maxWidth: "1400px",
                  }}
                >
                  {/*<div style={{ width: "100%", maxWidth: "500px" }}>*/}
                  {/*  <DomainInputWidget*/}
                  {/*    type={"text"}*/}
                  {/*    label="Subdomain"*/}
                  {/*    value={""}*/}
                  {/*    fieldId={"subdomain"}*/}
                  {/*    InputProps={{*/}
                  {/*      startAdornment: (*/}
                  {/*        <div style={{ paddingRight: "20px" }}>https://</div>*/}
                  {/*      ),*/}
                  {/*      endAdornment: (*/}
                  {/*        <div style={{ width: "260px" }}>{`.${domain}`}</div>*/}
                  {/*      ),*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <LoadingButton
                      type={"submit"}
                      variant={"contained"}
                      color={"success"}
                      loading={isLoading}
                    >
                      Save and Continue
                    </LoadingButton>
                  </div>
                </div>
              </form>
            </FormProvider>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{ textAlign: "start", paddingTop: "40px" }}>
                  <Typography
                    component={"span"}
                    variant={"h5"}
                    sx={{ fontWeight: "bold" }}
                  >
                    Personal details
                  </Typography>
                  <Typography variant={"subtitle1"}>
                    Add personal details about you
                  </Typography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "50px",
                    maxWidth: "1400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"firstName"}
                      label={"First name"}
                      type={"text"}
                      required={true}
                      value={""}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"lastName"}
                      required={true}
                      label={"Last name"}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "26px",
                    paddingTop: "50px",
                    maxWidth: "1400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <PhoneWidget
                      fieldId={"phone"}
                      label={"Phone number"}
                      required={true}
                      type={"phone"}
                      value={""}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"title"}
                      label={"Title"}
                      required={true}
                      type={"text"}
                      value={""}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "26px",
                    paddingTop: "50px",
                    maxWidth: "1400px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"emailPrimary"}
                      required={true}
                      label={"Primary E-mail"}
                      type={"email"}
                      value={""}
                      InputProps={{ disabled: true }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputWidget
                      fieldId={"emailNotification"}
                      label={"Notification E-mail"}
                      required={true}
                      type={"email"}
                      value={""}
                    />
                  </div>
                </div>
                <div style={{ textAlign: "start" }}>
                  <Typography variant={"caption"}>
                    WaiverKing uses this email to contact you about pending
                    upgrades, planned outages and other important announcements.
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "30px",
                    paddingTop: "50px",
                    maxWidth: "1400px",
                  }}
                >
                  <div style={{ width: "100%", maxWidth: "345px" }}>
                    {/*  <InputWidget*/}
                    {/*    fieldId={'seadSource'}*/}
                    {/*    label={'Lead Source'}*/}
                    {/*    type={'text'}*/}
                    {/*    value={''}*/}
                    {/*  />*/}
                  </div>
                  <LoadingButton
                    type={"submit"}
                    variant={"contained"}
                    color={"success"}
                    loading={isLoading}
                  >
                    Save and Continue
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BusinessAgreement cb={() => setIValues(1)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Box sx={{ paddingTop: "40px" }}>
              <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
                <Typography
                  component={"span"}
                  variant={"h5"}
                  sx={{ fontWeight: "bold" }}
                >
                  Security settings
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "85px",
                  padding: "20px",
                  marginBottom: "1px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  backgroundColor: "rgba(249, 250, 249, 1)",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ marginRight: "20px" }}>
                    <PasswordIcon />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      minWidth: "400px",
                    }}
                  >
                    <Typography variant={"subtitle1"}>
                      Password settings
                    </Typography>
                  </Box>
                </Box>
                <Box
                  component={"button"}
                  onClick={() => setIsPasswordModalOpen(true)}
                >
                  <EditIcon />
                </Box>
                <ChangePasswordModal
                  isPasswordModalOpen={isPasswordModalOpen}
                  setIsPasswordModalOpen={setIsPasswordModalOpen}
                  userEmail={userProfile?.emailPrimary}
                />
              </Box>
            </Box>
            <Box sx={{ paddingTop: "40px" }}>
              <Box sx={{ textAlign: "start", marginBottom: "30px" }}>
                <Typography
                  component={"span"}
                  variant={"h5"}
                  sx={{ fontWeight: "bold" }}
                >
                  Two-factor Authentication (2FA)
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "85px",
                  padding: "20px",
                  marginBottom: "10px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  backgroundColor: "rgba(249, 250, 249, 1)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "70%",
                  }}
                >
                  <GoogleIcon />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant={"subtitle1"}>
                      Google Authentication
                    </Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography variant={"body2"} sx={{ opacity: "0.5" }}>
                        Protect your account.
                      </Typography>
                      <Link
                        href={
                          "https://support.google.com/accounts/answer/1066447"
                        }
                        target="_blank"
                        rel="noreferrer"
                        variant="body2"
                        sx={{
                          color: "#2E7D32",
                          "&:hover": { textDecoration: "underline" },
                        }}
                      >
                        Instruction
                      </Link>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                  }}
                >
                  <CustomSwitch
                    checked={isAuthenticationOn("google_authenticator")}
                    sx={{ marginLeft: "auto" }}
                    onClick={() => {
                      if (isAuthenticationOn("google_authenticator")) {
                        setIsDisableVerificationModalOpen(true);
                        setAuthenticationType("google_authenticator");
                      } else {
                        setIsAuthenticationModalOpen(true);
                        setAuthenticationType("google_authenticator");
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "10%",
                  }}
                >
                  {isAuthenticationOn("google_authenticator") && (
                    <Box sx={{ display: "flex" }}>
                      <Box
                        component={"button"}
                        onClick={() => {
                          setIsAuthenticationModalOpen(true);
                          setAuthenticationType("google_authenticator");
                        }}
                      >
                        <EditIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "85px",
                  padding: "20px",
                  marginBottom: "10px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  backgroundColor: "rgba(249, 250, 249, 1)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "70%",
                  }}
                >
                  <EmailIcon />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant={"subtitle1"}>Email</Typography>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography variant={"body2"} sx={{ opacity: "0.5" }}>
                        Your email is used as the default verification method
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                  }}
                >
                  <Typography variant={"body1"} sx={{ opacity: "0.5" }}>
                    {
                      authenticationInfo[getAuthenticationIndex("email")]
                        ?.config?.email
                    }
                  </Typography>
                  <CustomSwitch
                    checked={isAuthenticationOn("email")}
                    sx={{ marginLeft: "auto" }}
                    onClick={() => {
                      if (isAuthenticationOn("email")) {
                        setIsDisableVerificationModalOpen(true);
                        setAuthenticationType("email");
                      } else {
                        setIsAuthenticationModalOpen(true);
                        setAuthenticationType("email");
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "10%",
                  }}
                >
                  {isAuthenticationOn("email") && (
                    <Box sx={{ display: "flex" }}>
                      <Box
                        component={"button"}
                        onClick={() => {
                          setIsAuthenticationModalOpen(true);
                          setAuthenticationType("email");
                        }}
                      >
                        <EditIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "85px",
                  padding: "20px",
                  marginBottom: "10px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  backgroundColor: "rgba(249, 250, 249, 1)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    width: "70%",
                  }}
                >
                  <IphoneIcon />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant={"subtitle1"}>Phone number</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                  }}
                >
                  <Typography variant={"body1"} sx={{ opacity: "0.5" }}>
                    {
                      authenticationInfo[getAuthenticationIndex("phone_number")]
                        ?.config?.phoneNumber
                    }
                  </Typography>
                  <CustomSwitch
                    checked={isAuthenticationOn("phone_number")}
                    sx={{ marginLeft: "auto" }}
                    onClick={() => {
                      if (isAuthenticationOn("phone_number")) {
                        setIsDisableVerificationModalOpen(true);
                        setAuthenticationType("phone_number");
                      } else {
                        setIsAuthenticationModalOpen(true);
                        setAuthenticationType("phone_number");
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "10%",
                  }}
                >
                  {isAuthenticationOn("phone_number") && (
                    <Box sx={{ display: "flex" }}>
                      <Box
                        component={"button"}
                        onClick={() => {
                          setIsAuthenticationModalOpen(true);
                          setAuthenticationType("phone_number");
                        }}
                      >
                        <EditIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <TwoFactorAuthenticationModal
                isAuthenticationModalOpen={isAuthenticationModalOpen}
                setIsAuthenticationModalOpen={setIsAuthenticationModalOpen}
                type={authenticationType}
                companyName={companyDetails?.name}
                getTwoFAInfo={getTwoFAInfo}
              />
              <DisableVerificationModal
                isDisableVerificationModalOpen={isDisableVerificationModalOpen}
                setIsDisableVerificationModalOpen={
                  setIsDisableVerificationModalOpen
                }
                type={authenticationType}
                getTwoFAInfo={getTwoFAInfo}
              />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Billing />
          </TabPanel>
          {/*<TabPanel value={value} index={5}>*/}
          {/*  Team*/}
          {/*</TabPanel>*/}
          <TabPanel value={value} index={5}>
            <EmergencyActions
              value={value}
              setIsLoadingAuthInfo={setIsLoadingAuthInfo}
            />
          </TabPanel>
        </Box>
      </>
    </>
  );
};

const getCardType = (type: "visa" | "mastercard"): JSX.Element | null => {
  switch (type) {
    case "visa":
      return <img src={visa} alt={"visa"} />;
    case "mastercard":
      return <img src={master} alt={"master"} />;
    default:
      return null;
  }
};

//TODO Create component

type CreditCardItemProps = {
  id: number;
  type: "visa" | "mastercard";
  cardNumberLastFour: string;
  selectedCardId: number | null;
  setSelectedCardId: (id: number) => void;
  onDelete: (id: number) => void;
  onEdit?: (id: number) => void;
};

export const CreditCardItem: FC<CreditCardItemProps> = ({
  id,
  type,
  selectedCardId,
  setSelectedCardId,
  cardNumberLastFour,
  onDelete,
}) => {
  const dispatchThunk = useAppThunkDispatch();

  const handleCardSelection = (id: number) => {
    setSelectedCardId(id);
    dispatchThunk(changeDefaultPaymentMethodThunk(id));
  };

  return (
    <Box
      padding={"0 20px"}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "841px",
        height: "71px",
        border: "1px solid #8899AE",
        borderRadius: "5px",
        textAlign: "start",
      }}
    >
      <Box sx={{ flex: "1" }}>
        <Typography variant={"subtitle2"}>Bank account</Typography>
        {getCardType(type)}
      </Box>
      <Box sx={{ flex: "1" }}>
        <Typography variant={"subtitle2"} paddingBottom={"8px"}>
          Card
        </Typography>
        {`**** **** **** ${cardNumberLastFour}`}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: "1" }}>
        <Box display={"flex"} alignItems={"center"}>
          <Radio
            color={"success"}
            checked={selectedCardId === id}
            name="radio-buttons"
            onClick={() => handleCardSelection(id)}
          />
          <Typography variant={"subtitle2"}>
            {selectedCardId === id ? "default" : "set as default"}
          </Typography>
        </Box>
        <EditDeleteCell
          data={{ id, deleteName: "payment method" }}
          onDelete={onDelete}
        />
      </Box>
    </Box>
  );
};

//TODO Create component

export type EditDeleteCellProps = {
  data: { id: number; deleteName: string };
  onDelete: (id: number) => void;
  onEdit?: (id: number) => void;
};

export const EditDeleteCell: FC<EditDeleteCellProps> = ({ data, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id, deleteName } = data;

  const handleDelete = () => {
    onDelete(id);
    setIsOpen(false);
  };

  return (
    <>
      <DeleteModalComponent
        open={isOpen}
        onDelete={handleDelete}
        onCancel={() => setIsOpen(false)}
        deleteName={deleteName}
      />
      <Stack direction="row" alignItems="center">
        {/*<IconButton aria-label="delete" size="small">*/}
        {/*  <Edit fontSize="inherit" />*/}
        {/*</IconButton>*/}
        <IconButton aria-label="delete" size="small">
          <Delete fontSize="inherit" onClick={() => setIsOpen(true)} />
        </IconButton>
      </Stack>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "24px",
};

//TODO Create component

const DeleteModalComponent: FC<{
  open: boolean;
  deleteName: string;
  onCancel: () => void;
  onDelete: () => void;
}> = ({ open, onCancel, onDelete, deleteName }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={"flex"} gap={"16px"} alignItems={"center"}>
            <img
              src={Illustration}
              width={"40px"}
              height={"40px"}
              alt={"Illustration"}
            />
            <Typography id="modal-modal-description">
              Are you sure you want to delete
            </Typography>
          </Box>
          <Box paddingLeft={"55px"}>
            <Typography id="modal-modal-description">{deleteName}?</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"12px"}
            marginTop={"16px"}
          >
            <Button variant={"outlined"} color="inherit" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant={"contained"} color="error" onClick={onDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

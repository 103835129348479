import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { clsx } from "clsx";
import { NavLink } from "react-router-dom";
import Button from "../../shared/Button";
import useMediaQuery from "../../hooks/useMediaQuery";
import useElementSize from "../../hooks/useElementSize";
import useScrollPosition from "../../hooks/useScrollPosition";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import { NavProps } from "../../shared/types";

// @ts-ignore
import mainlogo from "../../assets/mainlogo.svg";
// @ts-ignore
import hamburgericon from "../../assets/hamburgericon.svg";
// @ts-ignore
import closeiconblack from "../../assets/closeiconblack.svg";
// @ts-ignore
import faq from "../../assets/faq.svg";
// @ts-ignore
import contactus from "../../assets/contactus.svg";
// @ts-ignore
import support from "../../assets/support.svg";
// @ts-ignore
import callus from "../../assets/callus.svg";

const scrollYOffset = 7;

const Navbar = ({
  scrollToFAQ,
  scrollToContactUs,
  scrollToFeatures,
  scrollToHowItWorks,
  scrollToPricing,
  scrollToAboutUs,
}: NavProps) => {
  const [hidden, setHidden] = useState<boolean>(false);
  const [prevScrollPos, setPrevScrollPos] = useState<"up" | "down" | null>(
    null,
  );

  const [isMenuToggle, setIsMenuToggle] = useState<boolean>(false);
  const [stoppedScrollPosition, setStoppedScrollPosition] = useState<number>(0);

  const flexBetween = "flex items-center justify-between";
  const isAboveLargeScreen = useMediaQuery("(min-width: 1025px");
  const isAboveMediumScreen = useMediaQuery("(min-width: 744px");

  const [container, { width }] = useElementSize();

  const scrollDirection = useScrollDirection();
  const scrollY = useScrollPosition();

  const wrapper = document.querySelector(".landing-wrapper");

  const navMenuItems = [
    {
      label: "FEATURES",
      onClick: scrollToFeatures,
    },
    {
      label: "HOW IT WORKS",
      onClick: scrollToHowItWorks,
    },
    {
      label: "PRICING",
      onClick: scrollToPricing,
    },
    {
      label: "ABOUT US",
      onClick: scrollToAboutUs,
    },
  ];

  const burgerMenuItems = [
    {
      label: "FEATURES",
      onClick: scrollToFeatures,
    },
    {
      label: "HOW IT WORKS",
      onClick: scrollToHowItWorks,
    },
    {
      label: "PRICING",
      onClick: scrollToPricing,
    },
    {
      label: "ABOUT US",
      onClick: scrollToAboutUs,
    },
    {
      label: "FAQ",
      onClick: scrollToFAQ,
    },
    {
      label: "CONTACT US",
      onClick: scrollToContactUs,
    },
  ];

  const menuVars = {
    initial: {
      scaleX: 0,
    },
    animate: {
      scaleX: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      scaleX: 0,
      transition: {
        delay: 0.3,
        duration: 0.3,
      },
    },
  };
  const containerVars = {
    open: {
      transition: {
        delayChildren: 0.3,
      },
    },
  };
  const mobileLinkVars = {
    initial: {
      transition: {
        duration: 0.3,
      },
      opacity: 0,
    },
    open: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  useIsomorphicLayoutEffect(() => {
    if (scrollDirection === "up") {
      if (prevScrollPos === "down") {
        setStoppedScrollPosition(scrollY);
      }
      if (stoppedScrollPosition - scrollYOffset > scrollY) {
        setHidden(false);
      }

      return;
    }

    if (scrollDirection === "down" && scrollY > scrollYOffset) {
      setHidden(true);
    }
  }, [scrollY]);

  useIsomorphicLayoutEffect(() => {
    setPrevScrollPos(scrollDirection);
  }, [scrollDirection]);

  return (
    <>
      <nav ref={container}>
        <div
          style={{
            width,
            transform: `translateY(${!hidden ? "0" : "-200"}px)`,
          }}
          className={clsx(
            `fixed z-[100] transition-transform duration-1000 ease-in-out`,
          )}
        >
          {isAboveMediumScreen && (
            <div className="flex justify-end bg-primary-010 py-4 sm:px-[96px] md:px-[139px] lg:px-[150px]">
              <ul className={`${flexBetween} gap-8 text-sm `}>
                <li>
                  <NavLink
                    to="/"
                    className="flex cursor-pointer gap-2 transition duration-500 hover:text-primary-400"
                    onClick={() => {
                      setTimeout(() => scrollToFAQ(), 100);
                    }}
                  >
                    <img src={faq} alt={faq} />
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    className="flex cursor-pointer gap-2 transition duration-500 hover:text-primary-400"
                    onClick={() => {
                      setTimeout(() => scrollToContactUs(), 100);
                    }}
                  >
                    <img src={contactus} alt={contactus} />
                    Contact us
                  </NavLink>
                </li>
                <li>
                  <a
                    className="flex cursor-pointer gap-2 transition duration-500 hover:text-primary-400"
                    onClick={() => {
                      setTimeout(() => scrollToContactUs(), 100);
                    }}
                  >
                    <img src={support} alt="support" />
                    Support
                  </a>
                </li>
                <li>
                  <span className="flex items-center gap-2">
                    <img src={callus} alt="callus" /> +1 (888) 211-6693
                  </span>
                </li>
              </ul>
            </div>
          )}

          <div
            className={`${flexBetween} w-full bg-seconary-100 py-4 opacity-90 xs:px-[24px] sm:px-[96px] md:px-[139px] lg:px-[150px]`}
          >
            <div className={`${flexBetween} mx-auto w-full`}>
              <div className={`${flexBetween} w-full xs:gap-4 sm:gap-16 `}>
                <div className="flex flex-col [@media(min-width:1265px)]:flex-row items-center gap-0 [@media(min-width:1265px)]:gap-2">
                  <NavLink
                    to="/"
                    onClick={() =>
                      wrapper?.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      })
                    }
                  >
                    <img
                      className="xs:w-[87px] sm:w-[130px] lg:mt-[-10px] lg:w-[145px]"
                      src={mainlogo}
                      alt="logo"
                    />
                  </NavLink>
                  <span className="hidden [@media(min-width:614px)]:block text-primary-200">by WaiverKing</span>
                </div>
                {isAboveLargeScreen && (
                  <ul
                    className={`${flexBetween} gap-8 font-poppins text-sm font-semibold text-primary-700`}
                  >
                    {navMenuItems.map((item, index) => (
                      <li key={index}>
                        <NavLink
                          to="/"
                          className="cursor-pointer transition duration-500 hover:text-primary-400"
                          onClick={() => {
                            setTimeout(() => item.onClick(), 100);
                          }}
                        >
                          {item.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
                <div className={`${flexBetween}`}>
                  <Button
                    text={"Log in"}
                    style={
                      "text-primary-400 xs:text-sm xs:px-3 xs:py-1 xs:mr-2 bg-primary-010"
                    }
                    href={"/signin"}
                  />
                  <Button
                    text={"Sign up"}
                    style={
                      "text-primary-010 bg-primary-400 xs:text-sm xs:px-3 xs:py-1"
                    }
                    href={"/signup"}
                  />
                  {!isAboveLargeScreen && (
                    <button
                      className="ml-6"
                      onClick={() => setIsMenuToggle(true)}
                    >
                      <img src={hamburgericon} alt="hamburger" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {!isAboveLargeScreen && isMenuToggle && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed bottom-0 right-0 z-[101] flex h-full w-[254px] origin-right flex-col justify-between bg-primary-010 px-9 py-9 drop-shadow-xl"
          >
            <motion.div
              variants={containerVars}
              initial="initial"
              animate="open"
              exit="initial"
            >
              <motion.div
                variants={mobileLinkVars}
                className="mb-14 flex justify-end"
              >
                <button onClick={() => setIsMenuToggle(false)}>
                  <img src={closeiconblack} alt="close" />
                </button>
              </motion.div>
              <motion.div variants={mobileLinkVars}>
                <ul className="mb-14 flex flex-col gap-4 font-poppins text-base font-semibold text-primary-700">
                  {burgerMenuItems.map((item, index) => (
                    <li key={index}>
                      <NavLink
                        to="/"
                        className="cursor-pointer transition duration-500 hover:text-primary-400"
                        onClick={() => {
                          setIsMenuToggle(false);
                          setTimeout(() => item.onClick(), 100);
                        }}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                  {/*<li>*/}
                  {/*  <a*/}
                  {/*    className="flex items-center gap-2"*/}
                  {/*    onClick={() => {*/}
                  {/*      // setIsSupportModalOpen(true);*/}
                  {/*      setIsMenuToggle(false);*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    SUPPORT*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                  {/*TODO: we will need this later*/}
                </ul>
                <div className="mb-12 flex flex-col gap-4 font-poppins text-[13px] font-medium text-primary-700">
                  <NavLink
                    to="/privacy-policy"
                    onClick={() => setIsMenuToggle(false)}
                  >
                    PRIVACY POLICY
                  </NavLink>
                  <NavLink
                    to="/cookies-policy"
                    onClick={() => setIsMenuToggle(false)}
                  >
                    COOKIES POLICY
                  </NavLink>
                  <a
                    href={`javascript: Cookiebot.renew()`}
                    onClick={() => setIsMenuToggle(false)}
                  >
                    MANAGE COOKIES
                  </a>
                </div>
                <div className="w-full">
                  <Button
                    text={"Start free trial up"}
                    style={"text-primary-010 bg-primary-400 h-[42px]"}
                    href={"/signup"}
                  />
                  <p className="text-center font-roboto font-normal text-primary-300 xs:text-xs sm:text-sm">
                    No credit card required
                  </p>
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              variants={containerVars}
              initial="initial"
              animate="open"
              exit="initial"
            >
              <motion.div
                variants={mobileLinkVars}
                className="flex flex-col font-inter text-xs font-medium"
              >
                <p>+1 (888) 211-6693</p>
                <a
                  className="text-primary-200 underline"
                  href="mailto:contact_us@waiverking.com"
                >
                  contact_us@waiverking.com
                </a>
                <a
                  className="text-primary-200 underline"
                  href="mailto:support@waiverking.com"
                >
                  support@waiverking.com
                </a>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default Navbar;

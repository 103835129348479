import { FC, RefObject } from "react";

import Heading from "../../shared/Heading";
import { HowItWorksSlider } from "./HowItWorksSlider";
import { desktops, mobiles } from "modules/Landing/data/howItWorksData";
interface HowItWorksProps {
  howItWorksRef: RefObject<HTMLDivElement>;
}

const HowItWorks: FC<HowItWorksProps> = ({ howItWorksRef }) => {
  return (
    <section id="howitworks" ref={howItWorksRef}>
      <div className="xs:px-[24px] xs:pb-[70px] sm:px-[80px] sm:pb-[90px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading>
          Let us automate all your forms
          <br />
          Watch how it works:
        </Heading>
        <HowItWorksSlider desktopImages={desktops} mobileImages={mobiles} />
      </div>
    </section>
  );
};
export default HowItWorks;

import { FC, useRef, useState } from "react";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Stack } from "@mui/material";

import { useAppSelector, useAppThunkDispatch } from "../../store";
import { RecentDocument } from "../../store/features/client/clientSlice";
import { getClientDocumentThunk } from "../../store/features/thunk/clientThunk/clientThunk";
import { ClientPdfDocumentComponent } from "../ClientPdfDocument/ClientPdfDocumentComponent";
import { Loader } from "../Loader/Loader";


type RecentDocumentsIconsComponentProps = {
  recentDocuments?: RecentDocument[];
  clientUuid: string;
};

const gradientIcons: string[] = [
  `rgba(0, 128, 0, 1.00)`,
  `rgba(0, 90, 0, 0.7)`,
  `rgba(0, 46, 0, 0.58)`,
  `rgba(0, 26, 0, 0.54)`,
  `rgba(0, 0, 0, 0.54)`
];

export const RecentDocumentsIconsComponent: FC<
  RecentDocumentsIconsComponentProps
> = ({ recentDocuments, clientUuid }) => {
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const dispatchThunk = useAppThunkDispatch();
  const { clientDocument } = useAppSelector(({ client }) => client);


  const handleButtonClick = async (document: RecentDocument) => {
    setIsPreviewLoading(true);
    await dispatchThunk(
      getClientDocumentThunk({
        clientUuid: `${clientUuid || ""}`,
        documentUuid: `${document.crmDocumentId}`
      })
    );
    setIsPreviewLoading(false);
  };

  if (!recentDocuments) return null;
  return (
    <>
      <Loader isLoading={isPreviewLoading} />
      <div onClick={(event) => event.stopPropagation()}>
        <Stack
          direction='row'
          flexWrap='wrap'
          alignItems='center'
          justifyContent='center'
          spacing={0.5}
        >
          {recentDocuments.slice(0, 5).map((document, index) => {
            const gradientColor = gradientIcons[index];
            return (
              <Tooltip
                key={index}
                title={
                  <div style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "14px" }}>
                      {document.documentTemplate}
                    </span>
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      {moment(document.createdAt).format("MMMM D, YYYY")}
                    </span>
                  </div>
                }
                placement='bottom'
                arrow
                sx={{ textAlign: "center" }}
              >
                <IconButton
                  size='small'
                  onClick={() => handleButtonClick(document)}
                  sx={{
                    color: gradientColor
                  }}
                >
                  <DescriptionIcon fontSize='inherit' />
                </IconButton>
              </Tooltip>
            );
          })}
        </Stack>
        {clientDocument && clientDocument.userUuid === clientUuid &&
          <ClientPdfDocumentComponent clientDocument={clientDocument} />}
      </div>
    </>
  );
};

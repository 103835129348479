import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { Box, Button, Typography } from "@mui/material";
import { CreditCard } from "@mui/icons-material";
import creditCard from "card-validator";

//@ts-ignore
import visa from "../../../assets/img/visa.svg";
//@ts-ignore
import master from "../../../assets/img/master.svg";

import { useAppSelector, useAppThunkDispatch } from "store";
import {
  addPaymentMethodThunk,
  deletePaymentMethodThunk,
  getPaymentMethodsThunk,
} from "store/features/thunk/paymentThunk/paymentThunk";
import { CreditCardItem } from "./AccountComponent";
import { InputWidget } from "components/widgets/FormWidgets/InputWidget";
import { isCardExpired } from "helper/FormValidators/cardValidators";
import { checkMonthValidity } from "helper/FormValidators/monthValidators";

const Billing = () => {
  const formMethods = useForm();
  const { handleSubmit, watch, setError, clearErrors, reset } = formMethods;
  const [cardType, setCardType] = useState<any>({} as any);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  const dispatchThunk = useAppThunkDispatch();
  const { paymentMethods } = useAppSelector(({ payment }) => payment);

  const cardNumber = watch("cardNumber");

  useEffect(() => {
    dispatchThunk(getPaymentMethodsThunk());
  }, []);

  useEffect(() => {
    if (paymentMethods.length) {
      const defaultCard = paymentMethods.find(
        (method) => method.isDefault === true,
      );
      setSelectedCardId(defaultCard?.id ?? null);
    }
  }, [paymentMethods]);

  useEffect(() => {
    checkCardNumber(cardNumber || "");
  }, [cardNumber]);

  const clearForm = () => {
    reset();
  };

  const checkCardNumber = (value: string) => {
    const str = value.replace(/[_\s]/g, "");
    const card = creditCard.number(str);
    setCardType(card);

    if (card.isPotentiallyValid) {
      clearErrors("cardNumber");
    }
    if (!card.isPotentiallyValid) {
      setError("cardNumber", {
        type: "manual",
        message: "Invalid Card",
      });
      return;
    }
    if (card.card?.lengths.includes(str.length) && !card.isValid) {
      setError("cardNumber", {
        type: "manual",
        message: "Invalid Card",
      });
    }
  };

  const onSubmit = async (data: FieldValues) => {
    const asdas = {
      name: "creditCard",
      clientData: {
        ...data,
        type: cardType.card?.type,
      },
    };

    if (cardType.isValid) {
      await dispatchThunk(addPaymentMethodThunk(asdas));
      clearForm();
    }
  };

  const handleDeletePaymentMethod = (id: number) => {
    dispatchThunk(deletePaymentMethodThunk(id));
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginTop: "20px",
        }}
      >
        {Boolean(paymentMethods.length) && (
          <>
            <Typography
              textAlign={"start"}
              variant={"h6"}
              paddingBottom={"20px"}
              sx={{ fontWeight: "bold" }}
            >
              Added payment method
            </Typography>
            {paymentMethods.map(({ id, clientData }) => (
              <CreditCardItem
                key={id}
                id={id}
                type={"visa"}
                selectedCardId={selectedCardId}
                setSelectedCardId={setSelectedCardId}
                onDelete={handleDeletePaymentMethod}
                cardNumberLastFour={clientData?.cardNumberLastFour || "1234"}
              />
            ))}
          </>
        )}
      </Box>
      <FormProvider {...formMethods}>
        <form noValidate>
          <Box display={"flex"} gap={"30px"}>
            <Box maxWidth={"344px"}>
              <Box
                component={"div"}
                sx={{ textAlign: "start", paddingTop: "40px" }}
              >
                <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                  Add payment method
                </Typography>
              </Box>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"firstName"}
                    label={"First name"}
                    required={true}
                    type={"text"}
                    value={""}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"lastName"}
                    label={"Last name"}
                    required={true}
                    type={"text"}
                    value={""}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"cardNumber"}
                    label={"Card number"}
                    type={"text"}
                    value={""}
                    required={true}
                    InputProps={{
                      inputComponent: InputMask as any,
                      inputProps: {
                        mask: "9999 9999 9999 9999",
                        maskPlaceholder: "0000 0000 0000 0000",
                      },
                      endAdornment: (
                        <CreditCard sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"validity"}
                    label={"Term of validity"}
                    type={"text"}
                    required={true}
                    value={""}
                    InputProps={{
                      inputComponent: InputMask as any,
                      inputProps: {
                        mask: "99/99",
                        maskPlaceholder: "MM/YY",
                      },
                      endAdornment: (
                        <CreditCard sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
                      ),
                    }}
                    rules={{
                      required: "The field is required",
                      pattern: {
                        value: /^\d{2}\/\d{2}$/,
                        message: "Date format must be MM/YY",
                      },
                      validate: {
                        checkMonth: (value: string) => {
                          const [month] = value.split("/").map(Number);
                          const monthValidationResult =
                            checkMonthValidity(month);
                          return monthValidationResult || true;
                        },
                        checkExpiration: (value: string) => {
                          const isExpired = isCardExpired(value);
                          return isExpired ? "Card has expired" : true;
                        },
                      },
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <InputWidget
                    fieldId={"cvv"}
                    label={"CVV"}
                    type={"text"}
                    required={true}
                    value={""}
                    InputProps={{
                      inputComponent: InputMask as any,
                      inputProps: {
                        mask: "999",
                        maskPlaceholder: "cvv",
                      },
                      endAdornment: (
                        <CreditCard sx={{ color: "rgba(0, 0, 0, 0.5)" }} />
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div key={"address"} style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"address"}
                    label={"Street address"}
                    required={true}
                    type={"text"}
                    value={""}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"country"}
                    label={"Country"}
                    type={"text"}
                    required={true}
                    value={""}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: "26px", paddingTop: "30px" }}>
                <div key={"area"} style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"city"}
                    label={"City"}
                    type={"text"}
                    required={true}
                    value={""}
                  />
                </div>
                <div key={"area1"} style={{ width: "100%" }}>
                  <InputWidget
                    fieldId={"postCode"}
                    label={"Zip code"}
                    type={"text"}
                    required={true}
                    value={""}
                  />
                </div>
              </div>
            </Box>
            <Box
              sx={{
                paddingTop: "100px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant={"subtitle2"}>
                We accept the following cards
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", marginBottom: "auto" }}>
                <div>
                  <img src={visa} alt={"visa"} />
                </div>
                <div>
                  <img src={master} alt={"master"} />
                </div>
              </Box>
              <Box>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant={"contained"}
                  color={"success"}
                >
                  Add card
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Billing;

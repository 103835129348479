import { FC, RefObject, useEffect, useState } from "react";
import Heading from "../../shared/Heading";
import { FeatureType } from "../../shared/types";
import featuresData from "../../data/featuresData";
import Feature from "./Feature";
import useMediaQuery from "../../hooks/useMediaQuery";
import { CommentsSlider } from "../aboutUs/CommentsSlider";

interface FeaturesProps {
  featuresRef: RefObject<HTMLDivElement>;
}

const Features: FC<FeaturesProps> = ({ featuresRef }) => {
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const isMediaQueryLgMin = useMediaQuery("(min-width: 1025px)");
  const isMediaQueryXsMin = useMediaQuery("(min-width: 600px)");
  const isAboveLargeScreen = useMediaQuery("(min-width: 1024px");

  useEffect(() => {
    if (!isMediaQueryLgMin && isMediaQueryXsMin) {
      setItemsPerPage(2);
    } else if (!isMediaQueryXsMin) {
      setItemsPerPage(1);
    }
  }, [isMediaQueryLgMin, isMediaQueryXsMin]);

  return (
    <section id="features" ref={featuresRef}>
      {isMediaQueryLgMin ? (
        <div className="bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] flex flex-wrap gap-x-7 gap-y-20 pt-16 xs:px-[24px] xs:py-[40px] sm:px-[96px] sm:py-[72px] md:px-[139px] lg:px-[153px] lg:py-[100px] justify-center">
          <Heading isMarginNone>
            eSignature anytime and
            <br /> anywhere, on any device
          </Heading>
          {featuresData.map((feature: FeatureType) => (
            <Feature
              key={feature.title}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      ) : (
        <div className="bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] pt-[10px] pb-[100px]">
          <Heading>
            eSignature anytime and
            <br /> anywhere, on any device
          </Heading>
          <CommentsSlider itemsPerPage={itemsPerPage}>
            {featuresData.map((feature: FeatureType) => (
              <div className={"pb-[20px]"} key={feature.title}>
                <Feature
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </CommentsSlider>
        </div>
      )}
    </section>
  );
};
export default Features;

// @ts-ignore
import desktop1 from "../assets/desktop1.png";
// @ts-ignore
import desktop2 from "../assets/desktop2.png";
// @ts-ignore
import desktop3 from "../assets/desktop3.png";
// @ts-ignore
import desktop4 from "../assets/desktop4.png";
// @ts-ignore
import desktop5 from "../assets/desktop5.png";
// @ts-ignore
import desktop6 from "../assets/desktop6.png";

// @ts-ignore
import mobile1 from "../assets/mobile1.png";
// @ts-ignore
import mobile2 from "../assets/mobile2.png";
// @ts-ignore
import mobile3 from "../assets/mobile3.png";
// @ts-ignore
import mobile4 from "../assets/mobile4.png";
// @ts-ignore
import mobile5 from "../assets/mobile5.png";
// @ts-ignore
import mobile6 from "../assets/mobile6.png";

export const desktops = [
  desktop1,
  desktop2,
  desktop3,
  desktop4,
  desktop5,
  desktop6,
];

export const mobiles = [mobile1, mobile2, mobile3, mobile4, mobile5, mobile6];

import { FC, useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";

// @ts-ignore
import desktop from "../../assets/laptop.png";
// @ts-ignore
import mobile from "../../assets/mobile.png";
// @ts-ignore
import mobilepart from "../../assets/mobilepart.png";

// @ts-ignore
import arrowright from "../../assets/arrowrighticon.svg";
// @ts-ignore
import arrowleft from "../../assets/arrowlefticon.svg";

const SLIDE_INTERVAL = 3000;

interface HowItWorksSliderProps {
  desktopImages: string[];
  mobileImages: string[];
}

export const HowItWorksSlider: FC<HowItWorksSliderProps> = ({
  desktopImages,
  mobileImages,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = mobileImages.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
    }, SLIDE_INTERVAL);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages),
    onSwipedRight: () =>
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? totalImages - 1 : prevIndex - 1,
      ),
  });

  return (
    <div
      {...handlers}
      className="relative mb-[-30%] mt-[-14%] overflow-hidden md:mb-[-10%] md:mt-[-12%]"
    >
      <div className="relative hidden md:block">
        <img src={desktop} alt="Desktop frame" />
        <div className="absolute right-[21.2%] top-[21.2%] h-[47.2%] w-[50.2%]">
          {desktopImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Desktop ${index + 1}`}
              className={`absolute h-full w-full object-cover transition-opacity duration-1000 ${
                currentIndex === index ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
        </div>
      </div>

      <div className="relative right-[63%] top-0 mt-[-72%] w-[400%] md:absolute md:right-0 md:mt-0 md:w-auto">
        <img src={mobile} alt="Mobile frame" />
        <div className="absolute right-[64.6%] top-[32.9%] h-[45.5%] w-[14.2%] bg-primary-010 xs:rounded-3xl sm:rounded-[1.7rem] md:rounded-[0.6rem] lg:rounded-2xl xl:rounded-3xl">
          {mobileImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Mobile ${index + 1}`}
              className={`absolute h-full w-full object-cover transition-opacity duration-1000 xs:rounded-3xl sm:rounded-[1.7rem] md:rounded-[0.6rem] lg:rounded-2xl xl:rounded-3xl ${
                currentIndex === index ? "opacity-100" : "opacity-0"
              }`}
              onClick={() =>
                setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages)
              }
            />
          ))}
          <img
            src={mobilepart}
            alt=""
            className="absolute right-1/2 top-[2%] w-[27%] translate-x-1/2"
          />
        </div>
      </div>

      <div className="absolute bottom-[18%] w-full">
        <div className="flex items-center justify-center gap-2">
          {mobileImages.map((_, index) => (
            <div
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`h-2 w-2 cursor-pointer rounded-full ${
                currentIndex === index
                  ? "h-3 w-3 bg-primary-400"
                  : "bg-primary-300"
              }`}
            ></div>
          ))}
        </div>
      </div>

      <div className="absolute right-0 top-2/4 hidden -translate-y-1/2 lg:block">
        <div
          className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-primary-400"
          onClick={() =>
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages)
          }
        >
          <img src={arrowright} alt="Arrow right" className="h-2/4 w-2/4" />
        </div>
      </div>

      <div className="absolute left-0 top-2/4 hidden -translate-y-1/2 lg:block">
        <div
          className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-primary-400"
          onClick={() =>
            setCurrentIndex((prevIndex) =>
              prevIndex === 0 ? totalImages - 1 : prevIndex - 1,
            )
          }
        >
          <img src={arrowleft} alt="Arrow left" className="h-2/4 w-2/4" />
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { OverviewType } from "../../shared/types";
import Heading from "../../shared/Heading";
import Overview from "../../shared/Overview";
import Button from "../../shared/Button";

//@ts-ignore
import poster from "../../assets/howitworksposter.png";

const benefits: Array<OverviewType> = [
  {
    title: "We Do the Heavy Lifting:",
    description:
      "Simply send us your existing PDF, and we’ll create an interactive, online version for you. The first one’s on us, and after that, it's just $5 to $15 per form—an unbeatable deal!",
  },
  {
    title: "No Apps Required:",
    description:
      "Forget asking your customers to download an app. With WKForm, all they need is a link. They can complete and sign your forms with just a swipe of their finger.",
  },
  {
    title: "Secure and Reliable:",
    description:
      "Every signed form is stored securely in our system, with an automatic backup sent straight to your Dropbox. Peace of mind has never been so effortless.",
  },
  {
    title: "Affordable Pricing:",
    description:
      "For just $15, you can process 100 completed forms! It’s cost-effective, streamlined, and built to scale with your business.",
  },
  {
    title: "Versatile Form Solutions:",
    description:
      "From waivers to NDAs, contracts, membership agreements, and more—WKForm handles it all. Whatever type of form you have, we’ve got you covered.",
  },
  {
    title: "Lightning-Fast Workflow:",
    description:
      "Send your customers a link, and they can complete and sign the form in seconds. No more waiting for scanned signatures—your forms are back in your workflow instantly!",
  },
  {
    title: "No More Paper Chases:",
    description:
      "Eliminate the need for your customers to print, sign, scan, and email forms. WKForm makes it as simple as click, sign, done!",
  },
  {
    title: "Stay Organized with Our CRM:",
    description:
      "Keep track of who’s signed and who hasn’t with ease. Our CRM system ensures you’re always in the loop, so nothing falls through the cracks.",
  },
];

const Benefits: FC = () => {
  return (
    <section id="benefits">
      <div className="bg-seconary-100 shadow-[0px_-4px_20px_0px_rgba(0,0,0,0.15)_inset] xs:px-[24px] xs:pb-[70px] xs:pt-[10px] sm:px-[80px] sm:pb-[90px] md:px-[139px] lg:px-[153px] lg:py-[100px]">
        <Heading>Why Choose WKForm?</Heading>
        <div className="mb-20 grid grid-cols-[repeat(auto-fit,minmax(17rem,1fr))] xs:mt-[-35px] xs:w-[100%] xs:gap-6 sm:mt-0 sm:gap-y-12 md:gap-3 md:gap-y-12 lg:gap-y-20">
          {benefits.map((benefit: OverviewType) => (
            <Overview
              key={benefit.title}
              title={benefit.title}
              description={benefit.description}
            />
          ))}
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-16 xs:mt-[-35px] sm:mt-[-25px] md:mt-0">
          <div className="flex items-center justify-center xs:h-[185px] sm:h-[312px] md:h-[217px] md:w-1/2 lg:h-[300px]">
            <video className="h-full object-cover" poster={poster} controls>
              <source src="/wkform-how-it-works.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="flex flex-col items-center justify-center gap-8 lg:gap-12 xs:text-sm sm:text-base w-full lg:w-1/2">
            <p className="font-semibold text-primary-700">
              Join the future of form processing with WKForm and watch your
              business become more efficient, organized, and customer-friendly
              in no time. Let’s make paperwork a breeze!
            </p>
            <div className="w-fit">
              <Button
                text={"Sign up for a free 15 day trial"}
                style={"bg-primary-400 text-primary-010 mb-1"}
                href={"/signup"}
              />
              <p className="text-center text-sm">No credit card required</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "../../store";
import { setClientDocument } from "../../store/features/client/clientSlice";
import "./ClientPdfDocumentComponent.scss";
import { Button } from "@mui/material";


export const ClientPdfDocumentComponent: React.FC<any> = ({ clientDocument }: any) => {
  const dispatch = useAppDispatch();

  return (
    <>
        <div
          className="absolute left-0 top-0 z-[9999] h-full w-full"
          onClick={() => dispatch(setClientDocument(null))}
        >
          <div className="pdf-preview__container relative w-11/12">
            <Button
              className="btn__close"
              // otherwise the width is too bog, and css rule
              // regarding width of button does not apply
              size="small"
              aria-label="close"
              disableElevation
              variant="contained"
            >
              <CloseIcon className="icon__close" />
            </Button>
            <iframe
              title="document preview"
              className="pdf-preview__iframe"
              src={`data:${clientDocument.mimetype};base64,${clientDocument.binary}`}
            />
          </div>
        </div>
    </>
  );
};

import { FC } from "react";
import Heading from "../../shared/Heading";

// @ts-ignore
import deviceillustration from "../../assets/deviceillustration.png";

const Forms: FC = () => {
  return (
    <section id="forms">
      <div className="xs:px-[24px] xs:pt-[10px] sm:px-[80px] md:px-[139px] lg:px-[153px] lg:pt-[100px]">
        <Heading>
          eSignature anytime and
          <br />
          anywhere, on any device
        </Heading>
        <div className="flex flex-col items-center gap-7 lg:flex-row xl:items-start">
          <div className="order-2 mt-[-8%] lg:order-1 lg:mt-[-12%]">
            <img
              className="w-full"
              src={deviceillustration}
              alt="Device illustration"
            />
          </div>
          <div className="order-1 self-start pl-[30px] font-poppins font-semibold text-primary-700 md:w-[358px] lg:order-2 lg:w-[404px] lg:text-xl">
            <ul className="list-disc">
              <li>Waivers</li>
              <li>Intake Forms</li>
              <li>PARQ</li>
              <li>Membership Agreements</li>
              <li>Cancellation Forms</li>
              <li>Freeze Forms</li>
              <li>Treatment Notes</li>
              <li>Automatic Debit Authorization Forms</li>
              <li>Photo Release Forms</li>
              <li>Registration Forms</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forms;
